
import { useRef, useState, useEffect, useCallback} from 'react';
import { GoogleMap,  useJsApiLoader,Autocomplete, DirectionsRenderer } from '@react-google-maps/api';
import Buttonx from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import dayjs from 'dayjs';
import { Element } from 'react-scroll';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive';
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/lara-light-indigo/theme.css';  
import 'primereact/resources/primereact.min.css';                   
import 'primeicons/primeicons.css'; 
import { useTranslation } from 'react-i18next';

/* global google */
const libraries = ['places', 'routes']; // Constant array defined once

const URL_PROXY = process.env.REACT_APP_SERVER







const Booking = ({ id }) => {
  const { t } = useTranslation();

  //Google Map Variables 
  const [loadingMessage, setLoadingMessage] = useState('');
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const center = { lat: 46.5569, lng: 11.7087 }
  const [map, setMap] = useState(/** @type google.maps.Map */ (null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [showResult, setShowResult] = useState(false);
  const [price, setPrice] = useState(null);
  // New state variables for origin and destination
 const [origin, setOrigin] = useState('');
 const [destination, setDestination] = useState('');

  // Date Picker Value 
  const [selectedDate, setSelectedDate] = useState(null); 
  const minDate = dayjs().add(2, 'day').toDate(); // Update minDate to a JavaScript Date object

  // Time Picker Value 
  const [selectedTime, setSelectedTime] = useState(null); 

  const [elevationGain, setElevationGain] = useState(0);

  //Set styles
  const header = { fontSize: isMobile ? 70 : 45  ,color:"#023e48", marginBottom:20};
  const itemStyle = { fontSize: isMobile ? 40 : 20}; // Adjust font size
  

  
  //Loading states
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
 

  //Empty Checks 
  const [dateIsEmpty, setDateIsEmpty] = useState(false);
  const [timeIsEmpty, setTimeIsEmpty] = useState(false);
  const [persons, setPersons] = useState('');

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef(null)
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef(null)

  const navigate = useNavigate();
  

  const handleBookingNavigation = () => {
    const bookingData = {
      price : price,
      OriginAdress:origin,
      DestinationAdress:destination,
      Persons: persons,
      Time: dayjs(selectedTime).format('HH:mm'),
      Date: dayjs(selectedDate).format('DD-MM-YYYY')
    };
    navigate('/booking', { state: bookingData });
  };


  //Load Google Map
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: libraries,
  })



  const fetchElevationData = async (path) => {
    const elevationService = new google.maps.ElevationService();
    const elevationRequest = {
      'path': path,
      'samples': 16
    };
  
    return new Promise((resolve, reject) => {
      elevationService.getElevationAlongPath(elevationRequest, (result, status) => {
        if (status !== google.maps.ElevationStatus.OK) {
          reject('Elevation service failed due to: ' + status);
          return;
        }
        resolve(result);
      });
    });
  };
  
  // const calculateTotalElevationGain = (elevations) => {
  //   let totalGain = 0;
  //   for (let i = 1; i < elevations.length; i++) {
  //     if (elevations[i].elevation > elevations[i - 1].elevation) {
  //       totalGain += (elevations[i].elevation - elevations[i - 1].elevation);
  //     }
  //   }
  //   return totalGain;
  // };

//   const calculateTotalElevationGain = (elevations) => {
//     let totalGain = 0;
//     let totalLoss = 0;

//     for (let i = 1; i < elevations.length; i++) {
//         const elevationDifference = elevations[i].elevation - elevations[i - 1].elevation;
//         if (elevationDifference > 0) {
//             totalGain += elevationDifference;
//         } else {
//             totalLoss += Math.abs(elevationDifference);
//         }
//     }

//     return totalLoss > totalGain ? totalLoss : totalGain;
// };

const calculateTotalElevationGain = (elevations) => {
  let totalGain = 0;
  let totalLoss = 0;

  for (let i = 1; i < elevations.length; i++) {
      const elevationDifference = elevations[i].elevation - elevations[i - 1].elevation;
      if (elevationDifference > 0) {
          totalGain += elevationDifference;
      } else {
          totalLoss += Math.abs(elevationDifference);
      }
  }

  // Return the sum of total gain and total loss
  return totalGain + totalLoss;
};

const setRoute = useCallback(async () => {
  if (!origin || !destination || !isLoaded) {
    return; // Return early if origin, destination, or API isn't loaded
  }
  
  try {
    const directionsService = new google.maps.DirectionsService();
    
    const results = await directionsService.route({
      origin: origin,
      destination: destination,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);

    //Elevation Gain 
    const path = results.routes[0].overview_path;
    const elevationResults = await fetchElevationData(path);
    const totalElevationGain = calculateTotalElevationGain(elevationResults);
    setElevationGain(totalElevationGain);

    if (map) {
      const bounds = new google.maps.LatLngBounds();
      results.routes[0].legs[0].steps.forEach(step => {
        bounds.extend(step.start_location);
        bounds.extend(step.end_location);
      });
      map.fitBounds(bounds);
    }
  } catch (error) {
    console.error("Failed to calculate route:", error);
    alert('Something went wrong. Please try different locations.');
  }
}, [origin, destination, isLoaded, map]);


useEffect(() => {
  if (origin && destination && isLoaded) {
    setRoute();  // Recalculate route when origin or destination changes
  }
}, [origin, destination, isLoaded, setRoute]);  // This recalculates the route on changes

 // useEffect for origin and destination changes


 useEffect(() => {
  if (isLoaded && window.google) {  // Remove !directionsResponse to allow recalculation
    const centralLocation = new window.google.maps.LatLng(46.5569, 11.7087); // Example: Val Gardena 
    const options = {
      location: centralLocation, // Center of the radius
      radius: 2000000, // 200km in meters
    };

    const originAutocomplete = new window.google.maps.places.Autocomplete(originRef.current, options);
    originAutocomplete.addListener('place_changed', () => {
      const place = originAutocomplete.getPlace();
      setOrigin(place.formatted_address || '');
      setShowResult(false);  // Hide result on change
    });

    const destinationAutocomplete = new window.google.maps.places.Autocomplete(destiantionRef.current, options);
    destinationAutocomplete.addListener('place_changed', () => {
      const place = destinationAutocomplete.getPlace();
      setDestination(place.formatted_address || '');
      setShowResult(false);  // Hide result on change
    });

    return () => {
      google.maps.event.clearListeners(originAutocomplete, 'place_changed');
      google.maps.event.clearListeners(destinationAutocomplete, 'place_changed');
    };
  }
}, [isLoaded]);  // Trigger only when Google Maps API is loaded





useEffect(() => {
  setShowResult(false);
  // This logic will run when origin, destination, persons, selectedDate, or selectedTime changes
}, [persons, selectedDate, selectedTime]);

 

  //Is Loaded MAP 
  if (!isLoaded) {
    return <CircularProgress />;
  }


function handleResetInputs() {
  
  setSelectedDate(null);
  setSelectedTime(null);
  setShowResult(false);
  setPrice(null);
  setOrigin('');
  setDestination('');
  setDateIsEmpty(false);
  setTimeIsEmpty(false);

  clearRoute(); // If clearRoute is not needed, you can remove this line.
  setPersons('');

  if (originRef.current) {
    originRef.current.value = ''; // Clear the origin input field
  }
  if (destiantionRef.current) {
    destiantionRef.current.value = ''; // Clear the destination input field
  }

}

//Calculate Price 
  function calculatePrice (){
    setIsLoadingPrice(true);
    setLoadingMessage('Calculating price, starting server...');
    setDateIsEmpty(false);
    setTimeIsEmpty(false);


  
    if (selectedDate === null) {
      setDateIsEmpty(true);
    } else {
      setDateIsEmpty(false);
    }
  
    if (selectedTime === null) {
      setTimeIsEmpty(true);
    } else {
      setTimeIsEmpty(false);
    }
  
    if (selectedDate === null || selectedTime === null) {
      setIsLoadingPrice(false);
      return;
    }

    
    if (!distance || distance === '') {
      console.log("Distance is required.");
      alert('Please Select Addresses from the Dropdown!');
      setIsLoadingPrice(false);
      return; // Stop the function if distance is empty
    }

    if (!duration || duration === '') {
      console.log("Duration is required.");
      alert('Please Select Addresses from the Dropdown!');
      setIsLoadingPrice(false);
      return; // Stop the function if duration is empty
    } 


    //Log check 
    console.log("Distance", distance);
    console.log("Duration", duration);
    console.log("Persons", persons);
    console.log("Destination", destination);
    console.log("Origin", origin);
    console.log("Time", dayjs(selectedTime).format('HH:mm'));
    console.log("Date",  dayjs(selectedDate).format('DD-MM-YYYY'));


    

    const payload = {
      key: process.env.REACT_APP_DATABASE_KEY,
      distance: distance,
      time: dayjs(selectedTime).format('HH:mm'),
      date: dayjs(selectedDate).format('DD-MM-YYYY'),
      elevationGain: elevationGain,
      persons: persons // Format time as required by your backend
    };

    axios.post(`${URL_PROXY}/requestPrice`, payload)
    .then(response => {
      // Assuming the response body directly contains the calculated price
      const calculatedPrice = response.data.price;
      setPrice(calculatedPrice);
      setShowResult(true);
      console.log("Calculated Price:", calculatedPrice);
    })
    .catch(error => {
      console.error("Failed to calculate price due to API error:", error);
      alert('Failed to calculate price. Please try again.');
    })
    .finally(() => {
      // Stop loading once the server responds, whether it's successful or not
      setIsLoadingPrice(false);
    });
  }


// Clear Route function dont know if needed anymore 
  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
  

  }

  const handleChange = (event) => {
    setPersons(event.target.value);
  };


    return (
    <Element name={id}>
    <div  className="BookingContainer">
        <div className="TwoContainer">
        <div className="PageHeaderBooking">
            <p>{t('booking_booking')}</p>
        </div>
            <div className="DestiationContainer">
            <p style={header}>{t('booking_bookYourRide')} </p>
            <Autocomplete>
            <input  
            className="Origin" 
            label="Origin" 
            variant="outlined"  
            ref={originRef}
            placeholder={t('booking_enterLocation')}
            style={{
              width: isMobile ? '660px' : '210px',
              height: isMobile ? '120px' : '55px',
              padding: '18px', // Makes the input bigger
              fontSize: isMobile ? 45 : 18 , // Adjust font size as needed
              borderRadius: '8px', // Rounds the edges
              border: '1px solid #ccc' // Adds an outlined effect, customize as needed
            }}
            />
            </Autocomplete>
            <Autocomplete>
            <input
             label="Desination" 
             variant="outlined"   
             ref={destiantionRef}
             placeholder={t('booking_enterDestination')}
             style={{
              width: isMobile ? '660px' : '210px',
              height: isMobile ? '120px' : '55px',
          
              padding: '18px', // Makes the input bigger
              fontSize: isMobile ? 45 : 18 , // Adjust font size as needed
              borderRadius: '8px', // Rounds the edges
              border: '1px solid #ccc' // Adds an outlined effect, customize as needed
            }}
             />
            </Autocomplete>
            <FormControl>
            <InputLabel style={{ fontSize: isMobile ? '2rem' : '1rem', color: "#979797"}} >Persons</InputLabel>
            <Select
              id="PersonsSelect"
              value={persons}
              label={t('booking_persons')}
              onChange={handleChange}
              style={{ width: isMobile ? '660px' : '210px', 
                height: isMobile ? '120px' : '55px',
                fontSize: isMobile ? 45 : 18  }}
    
              
            >
            <MenuItem value={1} style={itemStyle}>1</MenuItem>
            <MenuItem value={2} style={itemStyle}>2</MenuItem>
            <MenuItem value={3} style={itemStyle}>3</MenuItem>
            <MenuItem value={4} style={itemStyle}>4</MenuItem>
            <MenuItem value={5} style={itemStyle}>5</MenuItem>
            <MenuItem value={6} style={itemStyle}>6</MenuItem>
            <MenuItem value={7} style={itemStyle}>7</MenuItem>
            </Select>
            </FormControl>
          
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <Calendar
                dateFormat="dd/mm/yy"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.value)}
                minDate={minDate}
                inline={false}
                placeholder={t('booking_selectDate')}
                showIcon
                style={{ 
                  width: isMobile ? '660px' : '210px', 
                  height: isMobile ? '120px' : '55px'  // Set this to the same height as the icon

                }}  
                inputStyle={{ 
                  width: isMobile ? '555px' : '40px', // Subtract the width of the icon from the input width
                  height: isMobile ? '120px' : '55px' , // Same height as the icon
                  fontSize: isMobile ? 45 : 18,
                  color: "black",
                  padding: '0 10px',  // Adjust padding to ensure text is centered
                  borderRadius: '8px 0 0 8px',  // Rounded corners on the left
                  borderRight: 'none'  // Remove right border to join with icon
                }}
                className={dateIsEmpty ? 'p-invalid' : ''}
              />
              <Calendar value={selectedTime} 
              onChange={(e) => setSelectedTime(e.value)} 
              placeholder={t('booking_selectTime')}
              timeOnly 
              stepMinute={15} 
              style={{ 
                width: isMobile ? '660px' : '210px', 
                height: isMobile ? '120px' : '55px'  // Set this to the same height as the icon

              }}  
              inputStyle={{ 
                width: isMobile ? '555px' : '1px', // Subtract the width of the icon from the input width
                height: isMobile ? '120px' : '55px' , // Same height as the icon
                fontSize: isMobile ? 45 : 18,
                color: "black",
                padding: '0 10px',  // Adjust padding to ensure text is centered
                borderRadius: '8px 8px 8px 8px',  // Rounded corners on the left
              }}
              className={timeIsEmpty ? 'p-invalid' : ''}
              />
            </LocalizationProvider>
            <div className="CalcAndCancel">
            <Buttonx variant="contained" style={{ backgroundColor: '#023e48', color: '#FFFFFF',height: isMobile ? 110 : 40 , width: isMobile ? 80 : 50 , marginRight: isMobile ? 10 : 15 , fontSize:isMobile ? 30 : 10, marginLeft:isMobile ? 0 : 20}} onClick={handleResetInputs} >X</Buttonx>
            
            <Buttonx variant="contained" style={{ backgroundColor: '#023e48', color: '#FFFFFF', height: isMobile ? 110 : 40 , width: isMobile ? 570 : 200 ,fontSize:isMobile ? 35 : 15 }} onClick={calculatePrice}>{t('booking_calculatePrice')}</Buttonx>
            {isLoadingPrice && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' , marginTop: 40}}>
                  <CircularProgress />
                  <p>{loadingMessage}</p>
                </div>
              )}
            </div>
        
          {showResult && price !== null && (
          <div className="ResultBox">
          <div className="ResultInfo">
              <p>{t('booking_price')} <span style={{ fontSize: isMobile ? 50 : 20 }}>{price}€</span></p>
              <p>{t('booking_distance')} {distance}</p>
              <p>{t('booking_duration')} {duration}</p>
          </div>
          <div className="ResultAction">
              <Buttonx variant="contained" style={{ backgroundColor: '#023e48', color: "#FFFFFF", height: isMobile ? 80 : 40 , width: isMobile ? 200 : 120 ,fontSize:isMobile ? 25 : 15, marginTop: isMobile ? 20 : 0}} onClick={handleBookingNavigation}>{t('booking_book')}</Buttonx>
          </div>
      </div>
          )}
        </div>
        <div className="MapContainer">
            <GoogleMap
           center={center}
           zoom={8}
           mapContainerClassName="map"
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={() => {
            setMap(map);
            setIsLoadingMap(false);
          }}
        >
          {!isLoadingMap && directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
        </GoogleMap>
        {isLoadingMap && <CircularProgress />} 
        </div>
        </div>
    </div>
    </Element>
      )
      }


export default Booking;